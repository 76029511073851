import { useMemo } from "react";
import * as R from "ramda";
import { useStaticQuery, graphql } from "gatsby";

import "./map-page.scss";

import {
  getMapIdType,
  useMapUrlId,
  MAP_DEPARTMENT,
  MAP_STATE,
  MAP_US,
} from "../../common/mapUrl.js";

import { extractBanner } from "../../common/contentful-extract.jsx";
import { useContentfulDepartments } from "../../common/contentfulDepartments.jsx";
import { navigateToMapEntity } from "../../common/mapUrl.js";

import DeptInfo from "../InfoPanel/DeptInfo.jsx";
import Banner from "../Banner/Banner.jsx";
import MapPanel from "../MapPanel/MapPanel.jsx";
import Page from "../Page/Page.jsx";
import ProgressSection from "./ProgressSection.jsx";
import StateInfo from "../InfoPanel/StateInfo.jsx";
import USInfo from "../InfoPanel/USInfo.jsx";

const MapPage = () => {
  const mapUrlId = useMapUrlId();
  const mapUrlType = getMapIdType(mapUrlId);

  const allDepartments = useContentfulDepartments();

  const panelInfo = useMemo(() => {
    // Don't render anything on the server so React doesn't get confused when it
    // loads and renders the panel based on the url
    // This has happened to me before https://github.com/gatsbyjs/gatsby/issues/14601
    if (typeof window === "undefined") {
      return null;
    }

    if (mapUrlType === MAP_US) {
      return <USInfo />;
    }
    // if there is a state matching
    else if (mapUrlType === MAP_STATE) {
      return <StateInfo abbreviation={mapUrlId} />;
    }
    // if there is something else, assume it's a dept ori
    else if (mapUrlType === MAP_DEPARTMENT) {
      const deptData = R.find(R.propEq("ori", mapUrlId), allDepartments);

      // if we don't have data, navigate to root map page
      if (R.isNil(deptData)) {
        navigateToMapEntity();
        return "";
      }
      // if we have data, render it
      else {
        return <DeptInfo ori={mapUrlId} deptData={deptData} />;
      }
    }
  }, [allDepartments, mapUrlType, mapUrlId]);

  const contentfulData = useStaticQuery(query);

  const data = R.compose(
    R.evolve({
      topHeroBanner: extractBanner,
      bottomBanner: extractBanner,
    }),
    R.defaultTo({}),
    R.prop("contentfulMapPage"),
  )(contentfulData);

  const { bottomBanner } = data;

  return (
    <Page title="Map">
      <ProgressSection />

      <MapPanel>{panelInfo}</MapPanel>

      <Banner {...bottomBanner} />
    </Page>
  );
};

export default MapPage;

const query = graphql`
  {
    contentfulMapPage {
      bottomBanner {
        backgroundImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundSize
        textColor
        subtitle {
          raw
        }
        title {
          raw
        }
        content {
          raw
        }
        link {
          ...Link
        }
      }
    }
  }
`;
