import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as R from "ramda";
import { number } from "prop-types";
import "./map.scss";

import createDotsOverlay from "./createDotsOverlay.js";
import { geoCentroid } from "d3-geo";

import theme, { labelColor } from "./theme.js";
import { quadtree } from "d3-quadtree";
import { getDeptStatus } from "../../common/dept.jsx";
import {
  getMapIdType,
  navigateToMapEntity,
  useMapUrlId,
  MAP_STATE,
  MAP_US,
  MAP_DEPARTMENT,
} from "../../common/mapUrl.js";
import { isStatusActive } from "./legendState.js";
import {
  lookupStateAbbreviation,
  lookupStateName,
} from "../../common/states.js";

import {
  Data,
  GoogleMap as ReactGoogleMap,
  useLoadScript,
} from "@react-google-maps/api";
import MapOverlay from "./MapOverlay.jsx";
import SelectedDeptOverlay from "./SelectedDeptOverlay.jsx";
import Popup from "../Popup/Popup.jsx";

import { useContentfulDepartments } from "../../common/contentfulDepartments.jsx";
import { useGoogleMapContext, statesGeoJson } from "./googleMapContext.jsx";
import { useLegendState } from "./legendState.js";
import { useMapScroll } from "../MapScroll/mapScrollContext.jsx";
import usePrevious from "../../common/usePrevious.js";

const propTypes = {
  width: number.isRequired,
  height: number.isRequired,
};

const DOT_ZONE_RADIUS = 40;
const INITAL_ZOOM = 4;
export const MIN_ZOOM = 3;
export const MAX_ZOOM = 22; // 22 is google map's default max zoom
const DEPT_INTERACTION_ZOOM = 6; // the min zoom to have click/hover interactions

// continental us center
const CONUS_CENTER = {
  lat: 39.8283,
  lng: -98.5795,
};

// https://en.wikipedia.org/wiki/List_of_extreme_points_of_the_United_States
// https://gist.github.com/jsundram/1251783
const CONUS_BOUNDS = {
  east: -66.9513812,
  north: 49.3457868,
  south: 24.7433195,
  west: -124.7844079,
};

const GoogleMap = (props) => {
  const { width, height } = props;

  const allDepartments = useContentfulDepartments();

  const { isLoaded, loadError } = useLoadScript({
    // policing-data@civilrights.org
    googleMapsApiKey: "AIzaSyDQJQlVEHYRQZywPHuSsHL1Lvx0S_YxZ30",
  });

  const { map, setMap, panToState } = useGoogleMapContext();
  const [zoom, setZoom] = useState(INITAL_ZOOM);
  const [center, setCenter] = useState(CONUS_CENTER);
  const [statesOverlay, setStatesOverlay] = useState();
  const [dotsOverlay, setDotsOverlay] = useState();
  const [deptQuadtree, setDeptQuadtree] = useState();

  const [hoveredStateName, setHoveredStateName] = useState();
  const [legendState, toggleLegendItem] = useLegendState();

  const containerRef = useRef();

  // const [undebouncedBounds, setBounds] = useState();

  const mapUrlId = useMapUrlId();
  const mapUrlType = getMapIdType(mapUrlId);
  // prevMapUrlId, only record if the map is loaded
  // using undefined because /map will match matpUrlId to null
  const prevMapUrlId = usePrevious(map ? mapUrlId : undefined);

  const { scrollToInfoPanel } = useMapScroll();

  /**
   * Selected Department
   */
  const selectedDeptData = useMemo(() => {
    if (mapUrlType === MAP_DEPARTMENT) {
      return R.find(R.propEq("ori", mapUrlId), allDepartments);
    }
  }, [allDepartments, mapUrlId, mapUrlType]);

  /**
   * scroll the map in to view when the map id changes
   */
  useEffect(() => {
    if (!R.isNil(prevMapUrlId)) {
      scrollToInfoPanel();
    }
  }, [prevMapUrlId, scrollToInfoPanel]);

  /**
   * update the state overlay styles
   */
  useEffect(() => {
    if (!statesOverlay) {
      return;
    }

    const usSelected = mapUrlType === MAP_US;

    // get the state name from the state abbreviation
    const selectedStateName =
      mapUrlType === MAP_STATE ? lookupStateName(mapUrlId) : null;

    // look up the dept info and get the stateName
    const selectedDeptStateName =
      mapUrlType === MAP_DEPARTMENT
        ? R.compose(
            R.prop("stateName"),
            R.defaultTo({}),
            R.find(R.propEq("ori", mapUrlId)),
          )(allDepartments)
        : null;

    statesOverlay.forEach((feature) => {
      const featureStateName = feature.getProperty("name");

      feature.setProperty("zoom", zoom);
      feature.setProperty("usSelected", usSelected);
      feature.setProperty(
        "stateSelected",
        featureStateName === selectedStateName,
      );
      feature.setProperty(
        "deptInStateSelected",
        featureStateName === selectedDeptStateName,
      );
    });
  }, [allDepartments, mapUrlId, mapUrlType, statesOverlay, zoom]);

  /**
   * move/zoom map to selected dept/state/US
   */
  useEffect(() => {
    // only move map  when the user selects a new dept/state/US
    if (!map || mapUrlId === prevMapUrlId) {
      return;
    }

    // if the US is selected
    if (mapUrlType === MAP_US) {
      // reset to center of US
      // setCenter(CONUS_CENTER);
      // setZoom(INITAL_ZOOM);
      map.fitBounds(CONUS_BOUNDS);
    }
    // special case for AK because google maps can't handle fitting to the bounds
    // of AK because it's western edge crosses the international date line
    else if (mapUrlType === MAP_STATE && mapUrlId === "AK") {
      const centroid = R.compose(
        R.zipObj(["lng", "lat"]),
        geoCentroid,
        R.find(R.pathEq(["properties", "name"], "Alaska")),
      )(statesGeoJson.features);

      setCenter(centroid);
      setZoom(5);
    }
    // if a state is selected
    else if (mapUrlType === MAP_STATE) {
      const selectedStateName = lookupStateName(mapUrlId);

      // don't zoom to the state if the user is x-ing out of a dept in that state
      const prevDeptIsInState = R.compose(
        R.equals(mapUrlId),
        R.prop("stateAbbreviation"),
        R.defaultTo({}),
        R.find(R.propEq("ori", prevMapUrlId)),
      )(allDepartments);

      // pan to state when the state stateBounds changes, but only if the user
      // isn't x-ing out of a dept
      if (!prevDeptIsInState) {
        panToState(selectedStateName);
      }
    }
    // if a Department is selected
    else if (mapUrlType === MAP_DEPARTMENT) {
      // if this department doesn't have a dot, pan to it's state
      // otherwise, map pan/zoom stuff is handled in SelectedDeptOverlay.jsx
      if (
        R.isNil(selectedDeptData.latitude) ||
        R.isNil(selectedDeptData.longitude)
      ) {
        if (selectedDeptData.stateName) {
          panToState(selectedDeptData.stateName);
        }
      }
    }
  }, [
    allDepartments,
    map,
    mapUrlId,
    mapUrlType,
    panToState,
    prevMapUrlId,
    selectedDeptData,
  ]);

  /**
   * keep track of the hovered state
   */
  useEffect(() => {
    if (statesOverlay) {
      statesOverlay.forEach((feature) => {
        const featureStateName = feature.getProperty("name");

        feature.setProperty("isHovered", featureStateName === hoveredStateName);
      });
    }
  }, [hoveredStateName, statesOverlay]);

  /**
   * Hovered Department
   */

  const [hoveredDeptOri, setHoveredDeptOri] = useState();
  const hoveredDept = useMemo(() => {
    // don't show the hover popup of a dept that is already selected
    if (hoveredDeptOri !== mapUrlId) {
      return R.find(R.propEq("ori", hoveredDeptOri), allDepartments);
    }
  }, [allDepartments, hoveredDeptOri, mapUrlId]);

  useEffect(() => {
    // deselect any hover value when the user zooms
    setHoveredDeptOri();
  }, [zoom]);

  /*
   * Dot interactions
   */

  const dotRadius = useMemo(() => {
    // we want the dots to be bigger when the user zooms in
    // zoom levels            3, 4, 5, 6, 7, 8, 9, 10,11, 12, 13
    const rLookup = [1, 1, 1, 1, 1, 2, 2, 3, 4, 6, 9, 12, 15, 20];
    return rLookup[zoom] || R.last(rLookup);
  }, [zoom]);

  // whether or not the user should be able to click/mouseover dots
  const [dotInterationDisabled, setDotInteractionDisabled] = useState(false);

  // reset the dotInterationDisabled state if the SelectedDeptOverlay is not there
  useEffect(() => {
    if (!selectedDeptData) {
      setDotInteractionDisabled(false);
    }
  }, [selectedDeptData]);

  const handleClick = useCallback(
    (e) => {
      if (!deptQuadtree || dotInterationDisabled) {
        return;
      }

      const { x, y } = e.pixel;
      const dept = deptQuadtree.find(x, y, dotRadius + DOT_ZONE_RADIUS);

      if (dept) {
        navigateToMapEntity(dept.ori);
      }
    },
    [deptQuadtree, dotInterationDisabled, dotRadius],
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!deptQuadtree || dotInterationDisabled) {
        setHoveredDeptOri(); // reset
        return;
      }

      const { x, y } = e.pixel;
      const dept = deptQuadtree.find(x, y, dotRadius + DOT_ZONE_RADIUS);

      // deselect if there is no close deptarment
      if (!dept) {
        setHoveredDeptOri();
      }
      // only update if it's different
      else if (dept && dept.ori !== hoveredDeptOri) {
        setHoveredDeptOri(dept.ori);
      }
    },
    [deptQuadtree, dotInterationDisabled, dotRadius, hoveredDeptOri],
  );

  const hoveredDeptPosition = useMemo(() => {
    if (!dotsOverlay || !hoveredDept) {
      return [];
    }

    return dotsOverlay.coordinatesToPoint([
      hoveredDept.longitude,
      hoveredDept.latitude,
    ]);
  }, [dotsOverlay, hoveredDept]);

  const drawDots = useCallback(
    (dotsOverlay) => {
      if (!dotsOverlay) {
        // console.warn("GoogleMap.drawDots: no dotsOverlay");
        return;
      }

      // don't let clicks happen until we've redrawn and recaclulated the quadtree
      setDeptQuadtree(null);

      dotsOverlay.drawDots({
        allDepartments,
        dotRadius,
        legendState,
        zoom,
        onDrawFinished: (deptsInBounds) => {
          // don't recalculate the quadtree when we don't need to
          if (zoom < DEPT_INTERACTION_ZOOM) {
            return;
          }

          // it's important to do this in onDrawFinished *after* the dotsOverlay
          // has drawn this is because the coordinatesToPoint method is statefull,
          // so we need to allow that to settle before using it to generate the quadtree
          const activeDepts = R.filter((dept) => {
            return isStatusActive(getDeptStatus(dept), legendState);
          })(deptsInBounds);

          const newDeptQuadtree = quadtree()
            .x(
              (dept) =>
                dotsOverlay.coordinatesToPoint([
                  dept.longitude,
                  dept.latitude,
                ])[0],
            )
            .y(
              (dept) =>
                dotsOverlay.coordinatesToPoint([
                  dept.longitude,
                  dept.latitude,
                ])[1],
            )
            .addAll(activeDepts);

          setDeptQuadtree(newDeptQuadtree);
        },
      });
    },
    [allDepartments, dotRadius, legendState, zoom],
  );

  // redraw the dots when the legendState changes
  useEffect(() => {
    drawDots(dotsOverlay);
    // need to HACK-ily do this because drawDots is imperitive
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [legendState]);

  // handle pressing escape when focused on the map
  useEffect(() => {
    // NOTE we want this event listener on the container, NOT the document
    // eg, if the user is focused in the search box and presses Escape, we
    // don't want this to fire
    const container = containerRef.current;

    if (container) {
      const handleKeyDown = (e) => {
        if (e.key === "Escape") {
          // find the parent, either the state, or the US
          const newMapEntity =
            mapUrlType === MAP_STATE
              ? "US"
              : mapUrlType === MAP_DEPARTMENT
              ? R.compose(
                  R.prop("stateAbbreviation"),
                  R.defaultTo({}),
                  R.find(R.propEq("ori", mapUrlId)),
                )(allDepartments)
              : null;

          if (!R.isNil(newMapEntity)) {
            navigateToMapEntity(newMapEntity);
          }
        }
      };

      container.addEventListener("keydown", handleKeyDown);

      return () => {
        container.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [allDepartments, mapUrlId, mapUrlType]);

  return (
    <div
      className="map__container"
      ref={containerRef}
      style={{ width, height }}
    >
      {loadError ? (
        <div className="map__loading">Error loading map</div>
      ) : isLoaded ? (
        // eslint disable for onMouseOut
        /* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */
        <ReactGoogleMap
          center={center}
          mapContainerStyle={{
            width,
            height,
            position: "absolute",
          }}
          onBoundsChanged={function () {
            // setBounds(this.getBounds());
          }}
          onCenterChanged={function () {
            setCenter(this.getCenter());
          }}
          onClick={handleClick}
          onDragStart={() => {
            setHoveredDeptOri(); // clear the popup when the user drags
          }}
          onIdle={() => {
            // scrollMapInToView() can't do this because it fires on first load
            drawDots(dotsOverlay);
          }}
          onLoad={(map) => {
            setMap(map);

            // can't use <OverlayView> because I need more control over when the
            // bounds update, or maybe I can if I just update the overlay bounds onIdle
            const dotsOverlay = createDotsOverlay({
              map,
              onAddCallback: (overlay) => {
                // need to inject overlay to avoid a race condition
                drawDots(overlay);
              },
            });
            setDotsOverlay(dotsOverlay);
          }}
          onMouseMove={zoom >= DEPT_INTERACTION_ZOOM ? handleMouseMove : null}
          onMouseOut={() => setHoveredDeptOri()} // clear
          onZoomChanged={function () {
            // google maps api relies on `this`
            const mapZoom = this.getZoom();
            if (mapZoom !== zoom) {
              setZoom(mapZoom);
            }
          }}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            maxZoom: MAX_ZOOM,
            minZoom: MIN_ZOOM,
            rotateControl: false,
            scaleControl: true,
            streetViewControl: false,
            styles: theme,
            zoomControl: false, // lives in MapOverlay.jsx
          }}
          zoom={zoom}
        >
          {/* states overlay */}

          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <Data
            onClick={(event) => {
              const name = event.feature.getProperty("name");
              const abbreviation = lookupStateAbbreviation(name);

              navigateToMapEntity(abbreviation);
            }}
            onMouseOver={(event) => {
              // set the hovered state
              const stateName = event.feature.getProperty("name");
              setHoveredStateName(stateName);

              // deselect any hovered dept
              if (hoveredDeptOri) {
                setHoveredDeptOri();
              }
            }}
            onMouseOut={(event) => {
              // reset
              setHoveredStateName();
              setHoveredDeptOri();
            }}
            onLoad={(statesOverlay) => {
              // load states overlay
              statesOverlay.addGeoJson(statesGeoJson);

              // https://developers.google.com/maps/documentation/javascript/examples/layer-data-dynamic
              // set up style, deptInStateSelected, etc, will be toggled elsewhere
              statesOverlay.setStyle((feature) => {
                const usSelected = feature.getProperty("usSelected");
                const deptInStateSelected = feature.getProperty(
                  "deptInStateSelected",
                );
                const stateSelected = feature.getProperty("stateSelected");
                const isHovered = feature.getProperty("isHovered");
                const zoom = feature.getProperty("zoom");

                // if we're zoomed in a bunch, remove the overlay (but keep the selected state stroke)
                if (zoom > 7) {
                  return {
                    clickable: false,
                    fillColor: "transparent",
                    fillOpacity: 1,
                    strokeColor: labelColor,
                    // highlight the selected state and always show the state lines
                    strokeOpacity: stateSelected ? 1 : 0.5,
                    strokeWeight: stateSelected ? 3 : 1,
                  };
                }

                const deptOrStateSelected =
                  deptInStateSelected || stateSelected;

                // https://developers.google.com/maps/documentation/javascript/reference/data#Data.StyleOptions
                return {
                  clickable: !deptOrStateSelected || usSelected,
                  // hide the overlay when us/state selected or zoomed way out
                  fillOpacity: 0,
                  strokeColor: labelColor,
                  strokeOpacity: 1,
                  strokeWeight: isHovered || deptOrStateSelected ? 3 : 0,
                };
              });

              setStatesOverlay(statesOverlay);
            }}
          />

          {selectedDeptData && (
            <SelectedDeptOverlay
              map={map}
              selectedDeptData={selectedDeptData}
              zoom={zoom}
              onMouseEnter={() => setDotInteractionDisabled(true)}
              onMouseLeave={() => setDotInteractionDisabled(false)}
            />
          )}
        </ReactGoogleMap>
      ) : (
        <div className="map__loading">Loading...</div>
      )}

      <MapOverlay
        legends={legendState}
        onLegendChange={toggleLegendItem}
        setZoom={setZoom}
        zoom={zoom}
      />

      <Popup
        isHidden={!hoveredDept}
        top={hoveredDeptPosition[1]}
        left={hoveredDeptPosition[0]}
        trianglePlacement="left"
        offset={dotRadius + 4}
        flipToContain="viewport"
        className="map__popup"
      >
        {hoveredDept && (
          <div className="map__popup-dept-name">{hoveredDept.name}</div>
        )}
      </Popup>
    </div>
  );
};

GoogleMap.propTypes = propTypes;
export default GoogleMap;
