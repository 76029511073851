import * as R from "ramda";

/**
 * uploadFileForDepartment
 * will upload the file and link it to the department
 * value for this department in contentful
 * @param  {File} file https://developer.mozilla.org/en-US/docs/Web/API/File
 * @param  {String} filename the new filename (not the filename the user uploaded it as)
 * @param  {String} ori Department ORI id
 * @param  {String} recaptchaToken token from recaptcha to test on server
 * @return {Promise}
 */
export const uploadFileForDepartment = (props) => {
  const { file, ori, recaptchaToken, recaptchaClientKey, filename } = props;

  // const url =
  //   "https://use-of-force-fa.azurewebsites.net/api/contentful-upload-dept-file?code=pKEm4C7iE7fO1UK/qs0f3ocAavHd0GQaoBL445pLb4wWrjP5lVsCYg==";

  const url =
    "https://accountable-now.azurewebsites.net/api/contentful-upload-dept-file?code=exVC/hMKu5x54w7CnHwomCHSy82r5YnIzZ9anLuC0dfblsMGQlHOaw==";

  // for testing locally with vscode https://docs.microsoft.com/en-us/azure/developer/javascript/tutorial/tutorial-vscode-serverless-node-test-local
  // const url = "http://localhost:7071/api/contentful-upload-dept-file";
  // const url = "http://192.168.1.161:7071/api/contentful-upload-dept-file";

  const formData = new FormData();
  formData.append("file", file);
  formData.append("ori", ori);
  formData.append("recaptchaToken", recaptchaToken);
  formData.append("recaptchaClientKey", recaptchaClientKey);
  formData.append("filename", filename);
  // do not include headers! https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
  return fetch(url, {
    method: "POST",
    body: formData,
  }).then((response) => {
    if (!response.ok) {
      response.json().then((json) => {
        // parse the message if there is one so it shows up nicely in the browser console
        const parsed = R.over(
          R.lensPath(["error", "message"]),
          safeParse,
          json,
        );
        console.error(`Error posting to ${url}`, parsed);
      });
      throw new Error(`Error posting to ${url}`);
    }

    return response.json();
  });
};

const safeParse = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return string;
  }
};
