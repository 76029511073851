import { useState } from "react";
import * as R from "ramda";
import { arrayOf, bool, oneOf, shape, string } from "prop-types";

import { HAS_DATA, HAS_FOIA_REJECTION, NO_DATA } from "../../common/dept.jsx";

const initialState = [
  {
    id: NO_DATA,
    label: "No Data Available",
    isActive: true,
  },
  {
    id: HAS_DATA,
    label: "Data Available",
    isActive: true,
  },
];

export const legendStateType = arrayOf(
  shape({
    id: oneOf(R.pluck("id", initialState)).isRequired,
    label: string.isRequired,
    isActive: bool.isRequired,
  }),
);

export function isStatusActive(status, legendState) {
  if (!legendState) {
    return true;
  }

  // group NO_DATA and HAS_FOIA_REJECTION
  const derrivedStatus = status === HAS_FOIA_REJECTION ? NO_DATA : status;

  return R.compose(
    R.propOr(false, "isActive"),
    R.defaultTo({}),
    R.find(R.propEq("id", derrivedStatus)),
  )(legendState);
}

export function useLegendState() {
  const [legendState, setLegendState] = useState(initialState);

  const toggleLegendItem = (id) => {
    setLegendState((prevState) => {
      return R.map(
        R.when(
          R.propEq("id", id),
          R.over(R.lensProp("isActive"), (v) => !v),
        ),
      )(prevState);
    });
  };

  return [legendState, toggleLegendItem];
}
