import * as R from "ramda";
import { useStaticQuery, graphql } from "gatsby";
import { extractRichText } from "./contentful-extract.jsx";

export default function useContentfulLocationInfo(locationName) {
  // can't use graphql variables in useStaticQuery, so get all the content
  // and do the filtering below
  // https://www.gatsbyjs.org/docs/use-static-query/
  const contentfulData = useStaticQuery(graphql`
    {
      allContentfulLocation {
        edges {
          node {
            location
            content {
              raw
              references {
                ...Link
              }
            }
          }
        }
      }
    }
  `);

  return R.compose(
    R.defaultTo({}),
    R.find(R.propEq("locationName", locationName)),
    R.map(
      R.compose(
        R.applySpec({
          locationName: R.prop("location"),
          content: R.compose(extractRichText, R.prop("content")),
        }),
        R.prop("node"),
      ),
    ),
    R.path(["allContentfulLocation", "edges"]),
  )(contentfulData);
}
