import { useMemo } from "react";
import * as R from "ramda";
import { string } from "prop-types";
import "./info-panel.scss";

import { getDeptStatus, HAS_DATA } from "../../common/dept.jsx";
import { formatNumber, formatPercentage } from "../../common/format.js";
import { getMapEntityUrl } from "../../common/mapUrl.js";
import { lookupStateName } from "../../common/states.js";

import GettingStarted from "./GettingStarted.jsx";
import { Link } from "@reach/router";
import InfoTooltip from "../InfoTooltip/InfoTooltip.jsx";
import ProgressChart from "../ProgressChart/ProgressChart.jsx";
import { useContentfulDepartments } from "../../common/contentfulDepartments.jsx";
import useContentfulLocationInfo from "../../common/useContentfulLocationInfo.js";
import { useGoogleMapContext } from "../GoogleMap/googleMapContext.jsx";

const propTypes = {
  abbreviation: string.isRequired,
};

const StateInfo = (props) => {
  const { abbreviation } = props;
  const stateName = lookupStateName(abbreviation);

  const allDepartments = useContentfulDepartments();
  const { content } = useContentfulLocationInfo(stateName);

  const { panToState } = useGoogleMapContext();

  const { collected, total } = useMemo(() => {
    return R.compose(
      R.applySpec({
        collected: R.compose(
          R.length,
          R.filter((dept) => getDeptStatus(dept) === HAS_DATA),
        ),
        total: R.length,
      }),
      R.filter(R.propEq("stateAbbreviation", abbreviation)),
    )(allDepartments);
  }, [abbreviation, allDepartments]);

  const percentage = collected / total;

  return (
    <div className="info-panel">
      <div className="info-panel__breadcrumbs">
        <Link to={getMapEntityUrl("US")}>United States</Link> /{" "}
        <Link
          to={getMapEntityUrl(abbreviation)}
          onClick={() => {
            // always pan to the state if this link is clicked
            // (logic in GoogleMaps.jsx) won't always
            panToState(stateName);
          }}
        >
          {stateName}
        </Link>
      </div>
      <h3 className="info-panel__title">{stateName}</h3>
      <ProgressChart percentage={percentage}>
        of Police Department Data in {stateName} has been collected
      </ProgressChart>
      <div className="info-panel__content">
        <h5>{stateName} Overview</h5>
        <p>
          Accountable Now has collected data for{" "}
          <strong className="info-panel__out-of">
            {formatNumber(collected)} out of {formatNumber(total)}
          </strong>
          <InfoTooltip>
            We referred to{" "}
            <a
              href="https://www.icpsr.umich.edu/web/pages/"
              target="_blank"
              rel="noreferrer"
            >
              ICPSR
            </a>{" "}
            as the source for number of departments.
          </InfoTooltip>
          law enforcement agencies in {stateName}. This accounts for{" "}
          <strong>{formatPercentage(percentage)}</strong> of the data for the
          state of {stateName}.
        </p>

        {content && (
          <div className="info-panel__content fancy-a">{content}</div>
        )}
      </div>

      <GettingStarted />
    </div>
  );
};

StateInfo.propTypes = propTypes;
export default StateInfo;
