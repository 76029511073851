import React from "react";
import { string, array, bool, func } from "prop-types";
import { createInputId, createErrorId } from "../../common/id-helpers.js";

const propTypes = {
  hasError: bool,
  required: bool,
  label: string,
  errorMsg: string,
  options: array,
  handleOnChange: func,
};

const SelectInput = React.forwardRef((props, ref) => {
  const { hasError, required, label, errorMsg, options, handleOnChange } =
    props;

  return (
    <div className="form__item">
      <label
        htmlFor={createInputId(label)}
        aria-required="true"
        className="form__label"
      >
        {label}
      </label>
      <select
        className="form__input"
        aria-required={required}
        id={createInputId(label)}
        ref={ref}
        aria-describedby={createErrorId(label)}
        required={required}
        onChange={handleOnChange}
      >
        {options &&
          options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
      </select>
      <div role="alert" id={createErrorId(label)} className="form__error">
        {hasError && errorMsg}
      </div>
    </div>
  );
});

SelectInput.propTypes = propTypes;
export default SelectInput;
