import * as R from "ramda";
import { useStaticQuery, graphql } from "gatsby";

import "./info-panel.scss";
import "./file-upload.scss";

import { extractRichText } from "../../common/contentful-extract.jsx";

import {
  deptShape,
  getDeptStatus,
  getDataIconImg,
  HAS_DATA,
  HAS_FOIA_REJECTION,
  NO_DATA,
} from "../../common/dept.jsx";
import { getMapEntityUrl } from "../../common/mapUrl.js";
import { useGoogleMapContext } from "../GoogleMap/googleMapContext.jsx";

import FileDownload from "./FileDownload.jsx";
import FileUpload from "./FileUpload.jsx";
import { Link } from "@reach/router";
import InfoForm from "./InfoForm.jsx";

const propTypes = {
  deptData: deptShape.isRequired,
};

const DeptInfo = (props) => {
  const { deptData } = props;

  const contentfulData = useStaticQuery(graphql`
    {
      contentfulMapPage {
        uploadHelpNoData {
          raw
          references {
            ...Link
          }
        }
        uploadHelpFoiaRejection {
          raw
          references {
            ...Link
          }
        }
        uploadHelpHasData {
          raw
          references {
            ...Link
          }
        }
      }
    }
  `);

  const data = R.compose(
    R.evolve({
      uploadHelpNoData: extractRichText,
      uploadHelpFoiaRejection: extractRichText,
      uploadHelpHasData: extractRichText,
    }),
    R.defaultTo({}),
    R.prop("contentfulMapPage"),
  )(contentfulData);

  const { panToState } = useGoogleMapContext();

  const {
    content,
    dataFiles,
    foiaRejections,
    name,
    ori,
    stateAbbreviation,
    stateName,
    phone,
    email,
  } = deptData;

  const deptStatus = getDeptStatus(deptData);

  const { uploadHelpNoData, uploadHelpFoiaRejection, uploadHelpHasData } = data;

  const uploadHelpText = R.cond([
    [R.equals(HAS_DATA), () => uploadHelpHasData],
    [R.equals(HAS_FOIA_REJECTION), () => uploadHelpFoiaRejection],
    [R.equals(NO_DATA), () => uploadHelpNoData],
  ])(deptStatus);

  return deptData ? (
    <div className="info-panel">
      <div className="info-panel__breadcrumbs">
        <Link to={getMapEntityUrl("US")}>United States</Link> /{" "}
        <Link
          to={getMapEntityUrl(stateAbbreviation)}
          onClick={() => {
            // always pan to the state if this link is clicked
            // (logic in GoogleMaps.jsx) won't always
            panToState(stateName);
          }}
        >
          {stateName}
        </Link>{" "}
        / {name}
      </div>
      <h3 className="info-panel__title">{name}</h3>
      <div className="info-panel__data">
        <div className="info-panel__icon">{getDataIconImg(deptStatus)}</div>{" "}
        {R.cond([
          [R.equals(HAS_DATA), () => "Data Available"],
          [R.equals(HAS_FOIA_REJECTION), () => "No Data Available"],
          [R.equals(NO_DATA), () => "No Data Available"],
        ])(deptStatus)}
      </div>

      {content && <div className="info-panel__content fancy-a">{content}</div>}

      {(dataFiles.length > 0 || foiaRejections.length > 0) && (
        <div className="info-panel__files">
          {dataFiles.map((dataFile) => {
            return <FileDownload key={dataFile.file} {...dataFile} />;
          })}

          {foiaRejections.map((foiaRejection) => {
            return <FileDownload key={foiaRejection.file} {...foiaRejection} />;
          })}
        </div>
      )}

      {uploadHelpText && (
        <div className="info-panel__content fancy-a">{uploadHelpText}</div>
      )}

      <div className="info-panel__content info-panel__content--hr">
        <h5>Upload Data</h5>
        {/* unique key so it resets when the user changes depts */}
        {name && <InfoForm key={ori} ori={ori} deptName={name} />}
      </div>

      {(!R.isNil(phone) || !R.isNil(email)) && (
        <div className="info-panel__dept-info">
          <h5>Contact the Agency</h5>

          {phone && (
            <div>
              Phone: <a href={`tel:${phone}`}>{phone}</a>
            </div>
          )}
          {email && (
            <div>
              Email: <a href={`mailto:${email}`}>{email}</a>
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
};

DeptInfo.propTypes = propTypes;
export default DeptInfo;
