// This class was based on the example:
// https://developers.google.com/maps/documentation/javascript/examples/overlay-simple

import * as R from "ramda";

import { HAS_DATA, getDataIconSrc, getDeptStatus } from "../../common/dept.jsx";
import { isStatusActive } from "./legendState.js";

const DEBUG = false;

export default function createDotsOverlay(...args) {
  /** @constructor */
  function DotsOverlay({ map, onAddCallback }) {
    // Initialize all properties.
    this.map = map;
    this.onAddCallback = onAddCallback;

    // this will update on every drawDots
    this.bounds = this.map.getBounds();

    // Explicitly call setMap on this overlay.
    this.setMap(map);
  }

  DotsOverlay.prototype = new window.google.maps.OverlayView();

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  DotsOverlay.prototype.onAdd = function () {
    const div = document.createElement("div");
    div.style.borderStyle = "none";
    div.style.borderWidth = "0px";
    div.style.position = "absolute";
    div.className = "dots-overlay__container";
    this.div = div;

    // Create the img element and attach it to the div.
    const img = document.createElement("img");
    img.style.width = "100%";
    img.style.height = "100%";
    img.style.position = "absolute";
    img.className = "dots-overlay__canvas";
    img.alt = ""; // for a11y
    this.img = img;
    div.appendChild(img);

    this.canvas = document.createElement("canvas");

    // Add the element to the "overlayLayer" pane.
    // https://developers.google.com/maps/documentation/javascript/customoverlays#initialize
    const panes = this.getPanes();
    panes.overlayLayer.appendChild(div);

    // fire consumer's callback and pass `this` so they can do stuff with it
    // (eg, call DotsOverlay.drawDots)
    this.onAddCallback && this.onAddCallback(this);
  };

  DotsOverlay.prototype.drawDots = function (props) {
    /* eslint-disable-next-line no-console */
    DEBUG && console.time("drawDots");

    const { allDepartments, dotRadius, onDrawFinished, legendState, zoom } =
      props;

    this.bounds = this.map.getBounds();

    const positions = this.getPositions(this.bounds);

    if (!positions) {
      DEBUG && console.warn("DotsOverlay.drawDots: no positions");
      return;
    }

    const { left, top, width, height } = positions;

    // Resize the image's div to fit the indicated dimensions.
    const div = this.div;
    div.style.left = left + "px";
    div.style.top = top + "px";
    div.style.width = width + "px";
    div.style.height = height + "px";

    const canvas = this.canvas;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = width;
    canvas.height = height;

    // only draw the dots that are visible (for performance)
    // also, some depts won't have lat/lon
    const deptsInBounds = allDepartments.filter((dept) => {
      return (
        !R.isNil(dept.latitude) &&
        !R.isNil(dept.longitude) &&
        this.bounds.contains({ lat: dept.latitude, lng: dept.longitude })
      );
    });

    deptsInBounds.forEach((dept) => {
      const [x, y] = this.coordinatesToPoint([dept.longitude, dept.latitude]);

      const deptStatus = getDeptStatus(dept);

      drawIcon(context, {
        deptStatus,
        cx: x,
        cy: y,
        // make triangles a bit bigger (because the teal is hard to see...)
        r: deptStatus === HAS_DATA ? dotRadius * 1.5 : dotRadius,
        isActive: isStatusActive(deptStatus, legendState),
        zoom,
      });
    });

    this.img.src = canvas.toDataURL();

    // alert the consumer that we're done (so they can calc quadtree, eg)
    // and pass along the dots that we drew
    onDrawFinished(deptsInBounds);

    /* eslint-disable-next-line no-console */
    DEBUG && console.timeEnd("drawDots");
  };

  DotsOverlay.prototype.draw = function () {
    if (!this.bounds) {
      DEBUG && console.warn("DotsOverlay.draw: no bounds");
      return;
    }

    // Resize the image's div to fit the indicated dimensions.
    const div = this.div;

    const { left, top, width, height } = this.getPositions(this.bounds);

    div.style.left = left + "px";
    div.style.top = top + "px";
    div.style.width = width + "px";
    div.style.height = height + "px";
  };

  DotsOverlay.prototype.getPositions = function (bounds) {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    const overlayProjection = this.getProjection();

    if (!overlayProjection) {
      console.warn("DotsOverlay.getPositions: no overlayProjection");
      return;
    }

    // Retrieve the south-west and north-east coordinates of this overlay
    // in LatLngs and convert them to pixel coordinates.
    // We'll use these coordinates to resize the div.
    const sw = overlayProjection.fromLatLngToDivPixel(bounds.getSouthWest());
    const ne = overlayProjection.fromLatLngToDivPixel(bounds.getNorthEast());

    return {
      left: sw.x,
      top: ne.y,
      width: ne.x - sw.x,
      height: sw.y - ne.y,
    };
  };

  /** The onRemove() method will be called automatically from the API if
   * we ever set the overlay's map property to 'null'.
   */
  DotsOverlay.prototype.onRemove = function () {
    this.div_.parentNode.removeChild(this.div_);
    this.div_ = null;
  };

  // this api matches d3-geo projection
  // this method is used in GoogleMap.jsx
  DotsOverlay.prototype.coordinatesToPoint = function (coordinates) {
    const projection = this.getProjection();

    if (!projection) {
      return [0, 0];
    }

    const [longitude, latitude] = coordinates;

    const point = projection.fromLatLngToContainerPixel(
      new window.google.maps.LatLng(latitude, longitude),
    );

    return [point.x, point.y];
  };

  return new DotsOverlay(...args);
}

// memoize this so it only has to create these Image's once per icon
// (instead of 18k times for each icon)
const getIconImg = R.memoizeWith(
  (status, zoom) => `${status}${zoom}`,
  (status, zoom) => {
    const icon = new Image();
    icon.src = getDataIconSrc(status, zoom);
    return icon;
  },
);

function drawIcon(context, props) {
  const { deptStatus, cx, cy, r, isActive = true, zoom } = props;

  const icon = getIconImg(deptStatus, zoom);

  const width = r * 2;
  const height = (icon.height / icon.width) * width;
  const x = cx - width / 2;
  const y = cy - height / 2;

  context.imageSmoothingEnabled = true; // apply anti-aliasing for scaled down images
  context.globalAlpha = isActive ? 1 : 0;
  context.drawImage(icon, x, y, width, height);
}
