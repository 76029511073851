import { node, shape, string } from "prop-types";
import { formatDate } from "../../common/format.js";

import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import InfoTooltip from "../InfoTooltip/InfoTooltip.jsx";

import "./file-download.scss";

const propTypes = {
  name: string,
  description: node,
  file: string,
  infoTooltip: shape({
    label: string,
    tooltipContent: node,
  }),
  uploaded: string,
};

const FileDownload = (props) => {
  const { name, description, file, infoTooltip, uploaded } = props;

  return (
    <div className="file-download">
      {name && <div className="file-download__name">{name}</div>}
      {description && (
        <div className="file-download__description fancy-a">{description}</div>
      )}
      <div className="file-download__uploaded">
        Uploaded on {formatDate(uploaded)}
      </div>

      {infoTooltip && (
        <div className="file-download__info-tooltip">
          <div className="file-download__info-tooltip-label">
            {infoTooltip.label}
          </div>{" "}
          <InfoTooltip trianglePlacement="bottom-start">
            {infoTooltip.tooltipContent}
          </InfoTooltip>
        </div>
      )}

      <ContentfulLink
        to={file}
        type="media"
        className="btn btn--dark file-download__download"
      >
        Download
      </ContentfulLink>
    </div>
  );
};

FileDownload.propTypes = propTypes;
export default FileDownload;
