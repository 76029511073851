import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { string } from "prop-types";
import FileUpload from "./FileUpload";
import "./form.scss";
import TextInput from "../Forms/TextInput";
import SelectInput from "../Forms/SelectInput";
import Modal from "../Modal/Modal.jsx";
import { scrollToCenter } from "../../common/scrollHelpers.js";
import * as R from "ramda";
import { graphql, useStaticQuery } from "gatsby";
import { format } from "date-fns";

import {
  DRAG_IDLE,
  DRAG_HOVERED,
  UPLOAD_INPROGRESS,
  UPLOAD_IDLE,
  UPLOAD_UPLOADING,
  UPLOAD_BAD_EXTENSION,
  validExtensions,
} from "./upload-helpers.js";

import {
  UPLOAD_RECAPTCHA_FAILED,
  UPLOAD_FILE_DENIED,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
} from "../../../azure-functions/contentful-upload-dept-file/uploadStatus.js";

import {
  RECAPTCHA_CLIENT_KEY_V3,
  RECAPTCHA_CLIENT_KEY_V2,
} from "../../../azure-functions/contentful-upload-dept-file/recaptcha-client.js";
import ReCAPTCHA from "react-google-recaptcha";
import { uploadFileForDepartment } from "../../common/azureFunctions.js";
import { extractRichText } from "../../common/contentful-extract.jsx";

const propTypes = {
  ori: string,
  deptName: string,
};

const InfoForm = (props) => {
  const { ori, deptName } = props;

  const reducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [token, setToken] = useState(null);

  const formRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const cellPhoneRef = useRef();
  const selectRef = useRef();
  const dropZoneRef = useRef();

  const initialErrorState = {
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    cellPhoneError: false,
    selectedFileError: false,
    selectSourceError: false,
  };
  const [errorData, setErrorData] = useReducer(reducer, initialErrorState);

  // file states
  const [uploadState, setUploadState] = useState(UPLOAD_IDLE);
  const [dragState, setDragState] = useState(DRAG_IDLE);
  const [selectedFile, setSelectedFile] = useState(null); // we're storing the file here for both drag and select
  const uploadedDate = Date.now();
  const timeStamp = format(uploadedDate, "yyyy-MM-dd--HH-mm-ss");
  const getExtension = (fileName) => R.compose(R.last, R.split("."))(fileName);

  // useEffect(() => {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: new Headers(),
  //     redirect: "follow",
  //   };
  //   fetch(
  //     "https://connect.civilrights.org/site/CRConsAPI?method=getLoginUrl&v=1.0&response_format=json&api_key=lcchr",
  //     requestOptions,
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setToken(result.getLoginUrlResponse);
  //     })
  //     .catch((error) => console.log("error", error));
  // }, []);

  useEffect(() => {
    if (errorData && selectedFile) {
      const allErrors = R.values(R.map((x) => x, errorData));
      const requiredInputArray = [
        firstNameRef.current.value,
        lastNameRef.current.value,
        emailRef.current.value,
        selectRef.current.value,
      ];

      const evaluateValues = R.filter(
        (x) => x.length === 0,
        requiredInputArray,
      );

      //we don't want to keep flagging errors when people tab through fields, so checking if value is populated and if errors exist before enabling/disabling
      evaluateValues.length > 0 ||
      selectedFile === null ||
      allErrors.filter((x) => x === true).length > 0
        ? setButtonDisabled(true)
        : setButtonDisabled(false);
    }
  }, [errorData, selectedFile]);

  useEffect(() => {
    if (uploadState === UPLOAD_IDLE && buttonDisabled === false) {
      setButtonDisabled(true);
    }
  }, [uploadState, buttonDisabled]);

  const uploadFileToApi = useCallback(
    (recaptchaToken, recaptchaClientKey, formData) => {
      const { file } = formData;

      // this shouldn't happen, but it can!
      if (!file) {
        setUploadState(UPLOAD_IDLE);
        return;
      }

      setUploadState(UPLOAD_UPLOADING);
      const filename = formData.filename;
      return uploadFileForDepartment({
        file,
        ori,
        recaptchaToken,
        recaptchaClientKey,
        filename,
      })
        .then((result) => {
          // default to error if uploadstatus not defined in result
          const { uploadStatus = UPLOAD_FILE_ERROR } = result;
          setUploadState(uploadStatus);
          submitData(formData);
        })
        .catch((error) => {
          console.error("Error uploading file", error);
          setUploadState(UPLOAD_FILE_ERROR);
        });
    },
    [ori],
  );

  const initiateFileUpload = useCallback(
    (formData) => {
      if (!window.grecaptcha) {
        console.error("window.grecaptcha not found!");
        setUploadState(UPLOAD_FILE_ERROR);
        return;
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(RECAPTCHA_CLIENT_KEY_V3, {
            action: "uploadFileForDepartment",
          })
          .then((recaptchaToken) => {
            return uploadFileToApi(
              recaptchaToken,
              RECAPTCHA_CLIENT_KEY_V3,
              formData,
            );
          })
          .catch((error) => {
            console.error(
              "Error executing recaptcha, is this domain in the allow list in the recaptcha admin?",
              error,
            );
            setUploadState(UPLOAD_FILE_ERROR);
          });
      });
    },
    [uploadFileToApi],
  );

  useEffect(() => {
    // scroll to the form
    if (
      formRef.current &&
      (uploadState === UPLOAD_UPLOADING ||
        uploadState === UPLOAD_FILE_SUCCESS ||
        uploadState === UPLOAD_FILE_ERROR ||
        uploadState === UPLOAD_FILE_DENIED)
    ) {
      scrollToCenter(formRef.current);
    }
  }, [uploadState]);

  const handleFileChosen = useCallback(
    (e) => {
      e.preventDefault();
      const file = e.target.files[0];

      if (!file) {
        console.error("error uploading file");
        return;
      }
      const extension = getExtension(file.name);

      // show an error if there is a bad extension
      if (!R.includes(extension, validExtensions)) {
        setUploadState(UPLOAD_BAD_EXTENSION);
        setErrorData({ selectedFileError: true });

        return;
      } else {
        setUploadState(UPLOAD_INPROGRESS);
        setErrorData({ selectedFileError: false });
        setSelectedFile(file);
      }
    },
    [setSelectedFile],
  );

  useEffect(() => {
    /**
     * https://codepen.io/nekobog/pen/JjoZvBm?editors=0010
     *
     * https://stackoverflow.com/questions/19841859/full-page-drag-and-drop-files-website/33917000#33917000
     * The basic idea is this:
     *   * Keep the drop zone hidden by default.
     *   * Attach a handler to the html element that will show the zone when something is being dragged over the page.
     *   * When the zone is visible, tap into its events to handle drag and drop.
     *   * When the mouse leaves the zone, hide it.
     *   * Here is the code that worked for me: https://jsfiddle.net/oL2akhtz/.
     *
     * This part of HTML5 spec is a little bit strange. The most important things to keep in mind are these:
     *
     *   * There are four interesting events: dragenter fires when we drag something and enter the bounds of an element; dragover fires every few ms while we are dragging something over the element; dragleave is the opposite of dragenter; drop fires when something is actually dropped.
     *   * You must listen to both dragenter and dragover on any element that is a valid drop target. That is, the element is turned into a valid drop target by listening to those events and cancelling their default actions. In our case this applies to the drop-zone overlay.
     *
     * Now I’ll try to explain my code.
     *
     *   1. First we add a listener that handles dragenter for the whole page. It just shows the overlay (and there is no need to prevent the default action as we actually do not intend to drop anything on the page, we will be dropping only on the overlay).
     *   2. Setup handlers for dragenter and dragover for the overlay. They prevent the default actions (which are to disallow dropping there) and also set dropEffect to give the user some nice feedback (although this seems to do nothing right now in modern browsers). This is a good place to test whether the item being dragged is valid.
     *   3. If the mouse leaves the drop zone (which is the same as the whole window, since the div covers everything) we hide it.
     *   4. Setup the actual drop handler.
     *
     * That’s it, pretty straightforward. Here is what happens when someone drops a file:
     *
     *   1. dragenter for the html element fires. It shows the dropzone.
     *   2. Since the mouse is now over the dropzone, dragleave for the html element fires, but we ignore it.
     *   3. dragenter for the dropzone fires and then dragover keeps firing. We just jeep saying that, yes, this is a valid drop target.
     *   4. Either drop fires, in which case we hide the dropzone and process the file, or dragleave for the dropzone fires in which case we just hide it.
     */
    if (dropZoneRef.current) {
      const dropZone = dropZoneRef.current;

      const preventDefaults = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

      const handleDrag = (e) => {
        preventDefaults(e);
        setDragState(DRAG_HOVERED);
      };

      const handleLeave = (e) => {
        preventDefaults(e);
        setDragState(DRAG_IDLE);
      };

      const handleDrop = (e) => {
        preventDefaults(e);
        setDragState(DRAG_IDLE);

        const file = e.dataTransfer.files[0];
        const extension = getExtension(file.name);

        // show an error if there is a bad extension
        if (!R.includes(extension, validExtensions)) {
          setUploadState(UPLOAD_BAD_EXTENSION);
          setErrorData({ selectedFileError: true });
          return;
        } else {
          setUploadState(UPLOAD_INPROGRESS);
          setErrorData({ selectedFileError: false });
          setSelectedFile(file);
        }
      };

      document.addEventListener("dragenter", handleDrag);

      // allow the drop to happen (the default is to disallow dropping)
      dropZone.addEventListener("dragenter", preventDefaults);
      dropZone.addEventListener("dragover", preventDefaults);

      dropZone.addEventListener("dragleave", handleLeave);
      dropZone.addEventListener("drop", handleDrop);

      return () => {
        document.removeEventListener("dragenter", handleDrag);

        dropZone.removeEventListener("dragenter", preventDefaults);
        dropZone.removeEventListener("dragover", preventDefaults);

        dropZone.removeEventListener("dragleave", handleLeave);
        dropZone.removeEventListener("drop", handleDrop);
      };
    }
  }, [initiateFileUpload]);
  // });

  const checkHasError = (fieldRef, errorProperty) => {
    const value = fieldRef.value;
    const type = fieldRef.getAttribute("type");
    const required = fieldRef.getAttribute("required");
    let hasError = false;
    switch (type) {
      case "email":
        hasError = !value.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i);
        break;
      case "tel":
        hasError =
          !value.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/) &&
          value.trim() !== "";
        break;
      case "checkbox":
      case "select":
      case "text":
      default:
        if (
          required !== null &&
          (value.trim().length === 0 || !value.match(/^[a-zA-Z\s-'"]*$/g))
        ) {
          hasError = true;
        }

        break;
    }
    setErrorData({ [errorProperty]: hasError });
    return hasError;
  };

  const handleBlur = (fieldRef, setErrorProperty) => {
    const value = fieldRef.value;
    if (value.length > 0) {
      checkHasError(fieldRef, setErrorProperty)
        ? setButtonDisabled(true)
        : null;
    } else if (fieldRef.getAttribute("required") === null) {
      checkHasError(fieldRef, setErrorProperty)
        ? setButtonDisabled(true)
        : null;
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    //shouldn't but let's double check just in case
    const extension = getExtension(selectedFile.name);
    const fileHasError =
      selectedFile &&
      !R.includes(extension, validExtensions) &&
      uploadState !== UPLOAD_INPROGRESS;
    const errorArray = R.values(errorData);
    const filename = `${ori}--${timeStamp}.${extension}`;
    setButtonDisabled(true);

    if (errorArray.filter((x) => x === true).length === 0) {
      const formData = {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        email: emailRef.current.value,
        phone: cellPhoneRef.current.value,
        source: selectRef.current.value,
        filename: filename,
        file: selectedFile,
        token: token,
      };

      initiateFileUpload(formData);
    } else {
      fileHasError && setErrorData({ selectedFileError: true });
      return;
    }
  };

  const submitData = (formData) => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };
    fetch(
      "https://connect.civilrights.org/site/CRConsAPI?method=getLoginUrl&v=1.0&response_format=json&api_key=lcchr",
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        setToken(result.getLoginUrlResponse);
        const { firstName, lastName, email, phone, source, filename } =
          formData;
        const token = result.getLoginUrlResponse;
        const bodyParams = new URLSearchParams();
        bodyParams.append("method", "submitSurvey");
        bodyParams.append("v", "1.0");
        bodyParams.append("response_format", "json");
        bodyParams.append("api_key", "lcchr");
        bodyParams.append("auth", token.token);
        bodyParams.append("survey_id", "1461");
        bodyParams.append("question_1382", firstName);
        bodyParams.append("question_1383", lastName);
        bodyParams.append("question_1385", email);
        bodyParams.append("question_1384", phone);
        bodyParams.append("question_1381", source);
        bodyParams.append("question_1386", filename);
        fetch(token.url.replace("CRConsAPI", "CRSurveyAPI"), {
          method: "POST",
          headers: new Headers().append("Content-Type", "application/json"),
          redirect: "follow",
          body: bodyParams,
        })
          .then((response) => response.json())
          .catch((e) => console.warn(e));
      })
      .catch((error) => console.log("error", error));
  };

  const backupOptions = ["FOIA request", "Online", "Other"];

  const contentfulData = useStaticQuery(query);

  const legalText =
    contentfulData.contentfulMapPage.formLegalText &&
    extractRichText(contentfulData.contentfulMapPage.formLegalText);

  const options = contentfulData
    ? contentfulData.contentfulMapPage.uploadDataSourceOptions
    : backupOptions;

  const dataSourceOptions = options.map((x) => x);
  dataSourceOptions.unshift("");
  return (
    <form className="form" ref={formRef} onSubmit={onFormSubmit}>
      {uploadState !== UPLOAD_FILE_SUCCESS && (
        <>
          <div className="form__row form__row--text">
            Upload a file to submit new data to the progress map.
          </div>
          <div className="form__row">
            <span className="form__required-text">*denotes required field</span>
          </div>
          <div className="form__row">
            <TextInput
              hasError={errorData.firstNameError}
              label="First Name*"
              required={true}
              ref={firstNameRef}
              errorMsg="Please enter first name"
              handleBlur={() =>
                handleBlur(firstNameRef.current, "firstNameError")
              }
            />
            <TextInput
              hasError={errorData.lastNameError}
              label="Last Name*"
              ref={lastNameRef}
              required={true}
              handleBlur={() =>
                handleBlur(lastNameRef.current, "lastNameError")
              }
              errorMsg="Please enter last name"
            />
          </div>
          <div className="form__row">
            <TextInput
              hasError={errorData.emailError}
              label="Email*"
              ref={emailRef}
              required={true}
              placeholder="e.g. your.name@example.com"
              type="email"
              handleBlur={() => handleBlur(emailRef.current, "emailError")}
              errorMsg="Please enter a valid email address"
            />
          </div>
          <div className="form__row">
            <TextInput
              hasError={errorData.cellPhoneError}
              label="Cell phone number"
              ref={cellPhoneRef}
              required={false}
              type="tel"
              placeholder="(000) 000-0000"
              handleBlur={() =>
                handleBlur(cellPhoneRef.current, "cellPhoneError")
              }
              errorMsg="invalid phone number"
            />
          </div>
          <div className="form__row">
            <SelectInput
              hasError={errorData.selectSourceError}
              required={true}
              label="How did you obtain the data?*"
              errorMsg="Please select a source"
              options={dataSourceOptions}
              ref={selectRef}
              handleOnChange={() =>
                checkHasError(selectRef.current, "selectSourceError")
              }
            />
          </div>
        </>
      )}
      <div className="form__row form__row--file-upload">
        <FileUpload
          key={ori}
          ori={ori}
          deptName={deptName}
          uploadState={uploadState}
          setUploadState={setUploadState}
          dragState={dragState}
          ref={dropZoneRef}
          handleFileChosen={handleFileChosen}
          hasError={errorData.selectedFileError}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </div>
      {uploadState !== UPLOAD_FILE_SUCCESS && (
        <>
          <div className="form__row form__row--text">
            <button
              disabled={buttonDisabled}
              type="submit"
              className="btn form__btn btn--dark"
            >
              Upload Data
            </button>
          </div>
          <Modal
            isOpen={uploadState === UPLOAD_RECAPTCHA_FAILED}
            onClose={() => setUploadState(UPLOAD_IDLE)}
          >
            <div className="file-upload__recaptcha-v2">
              <h5>On no!</h5>
              <p>
                You might be a robot! Please check the box below to show us that
                you're human!
              </p>
              <ReCAPTCHA
                sitekey={RECAPTCHA_CLIENT_KEY_V2}
                onChange={(recaptchaToken) => {
                  uploadFileToApi(recaptchaToken, RECAPTCHA_CLIENT_KEY_V2, {
                    firstName: firstNameRef.current.value,
                    lastName: lastNameRef.current.value,
                    email: emailRef.current.value,
                    phone: cellPhoneRef.current.value,
                    source: selectRef.current.value,
                    filename: `${ori}--${timeStamp}.${getExtension(
                      selectedFile.name,
                    )}`,
                    file: selectedFile,
                    token: token,
                  });
                }}
              />
            </div>
          </Modal>
        </>
      )}{" "}
      <div className="file-upload__recaptch-message">
        {" "}
        {legalText ? (
          legalText
        ) : (
          <>
            This site is protected by reCAPTCHA and the Google{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            apply.
          </>
        )}
      </div>
    </form>
  );
};

InfoForm.propTypes = propTypes;
export default InfoForm;

const query = graphql`
  {
    contentfulMapPage {
      uploadDataSourceOptions
      formLegalText {
        raw
      }
    }
  }
`;
