import * as R from "ramda";
import {} from "prop-types";
import { extractRichText } from "../../common/contentful-extract.jsx";

import "./getting-started.scss";

const propTypes = {};

import { useStaticQuery, graphql } from "gatsby";

const GettingStarted = (props) => {
  const contentfulData = useStaticQuery(graphql`
    {
      contentfulMapPage {
        instructionText {
          raw
          references {
            ...Link
          }
        }
      }
    }
  `);

  const content = R.compose(
    extractRichText,
    R.path(["contentfulMapPage", "instructionText"]),
  )(contentfulData);

  return <div className="info-panel__content getting-started">{content}</div>;
};

GettingStarted.propTypes = propTypes;
export default GettingStarted;
