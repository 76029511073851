import { useMemo } from "react";
import * as R from "ramda";
import { useStaticQuery, graphql } from "gatsby";

import {} from "prop-types";
import "./progress-section.scss";
import "../../scss/fancy-bold.scss";

import { formatNumber } from "../../common/format.js";
import { useContentfulDepartments } from "../../common/contentfulDepartments.jsx";
import { getDeptStatus, HAS_DATA } from "../../common/dept.jsx";
import { extractRichText } from "../../common/contentful-extract.jsx";
import { useMapScroll } from "../MapScroll/mapScrollContext.jsx";

import Container5050 from "../Container/Container5050.jsx";
import ProgressChart from "../ProgressChart/ProgressChart.jsx";

const propTypes = {};

// the # of depts to collect data for in  2021
const GOAL = 1000;

const Progress = (props) => {
  const allDepartments = useContentfulDepartments();

  const { scrollToInfoPanel } = useMapScroll();

  const { collected } = useMemo(() => {
    return R.applySpec({
      collected: R.compose(
        R.length,
        R.filter((dept) => getDeptStatus(dept) === HAS_DATA),
      ),
      total: R.length,
    })(allDepartments);
  }, [allDepartments]);

  const percentage = Math.min(1, collected / GOAL);

  const contentfulData = useStaticQuery(query);

  const data = R.compose(
    R.evolve({
      progressSectionTitle: extractRichText,
      progressText: extractRichText,
    }),
    R.defaultTo({}),
    R.prop("contentfulMapPage"),
  )(contentfulData);

  const { progressSectionTitle, progressText } = data;

  return (
    <section className="progress-section">
      <Container5050
        className="progress-section__container"
        swapStack={true}
        left={
          <div className="progress-section__text fancy-bold">
            <div>
              <h2>{progressSectionTitle}</h2>

              {progressText}

              <button
                type="button"
                className="btn btn--text"
                onClick={(e) => scrollToInfoPanel()}
              >
                Start Searching Below
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>
            </div>
          </div>
        }
        right={
          <div className="progress-section__chart">
            <ProgressChart percentage={percentage}>
              <h6>of our goal has been reached for 2021.</h6>
            </ProgressChart>

            <div className="progress-section__chart-label progress-chart__label">
              Our goal is to collect {formatNumber(GOAL)} data sets in 2021.{" "}
              <strong>
                {formatNumber(collected)}/{formatNumber(GOAL)}
              </strong>{" "}
              data sets have been collected for use of force data across police
              departments in the United States so far.
            </div>
          </div>
        }
      />
    </section>
  );
};

Progress.propTypes = propTypes;
export default Progress;

const query = graphql`
  {
    contentfulMapPage {
      progressSectionTitle {
        raw
      }
      progressText {
        raw
      }
    }
  }
`;
