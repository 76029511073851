import * as R from "ramda";
import { node, number } from "prop-types";

import "./progress-chart.scss";

import { arc } from "d3-shape";
import { formatPercentageNice } from "../../common/format.js";

import useBoundingClientRect from "../../common/useBoundingClientRect.js";

const propTypes = {
  children: node,
  collected: number, // show this instead of percentage if provided
  percentage: number.isRequired, // 0-1
  title: node,
};

const ProgressChart = (props) => {
  const { children, collected, percentage, title } = props;

  const [{ width = 0 }, containerRef] = useBoundingClientRect();

  // max size
  const size = R.min(width, 380);

  const arcGenerator = arc();

  const backgroundCircle = arcGenerator({
    innerRadius: size / 2 - 32,
    outerRadius: size / 2,
    startAngle: 0,
    endAngle: Math.PI * 2,
  });

  const percentageArc = arcGenerator({
    innerRadius: size / 2 - 32,
    outerRadius: size / 2,
    startAngle: 0,
    endAngle: Math.PI * 2 * percentage,
  });

  return (
    <div className="progress-chart" ref={containerRef}>
      <div
        className="progress-chart__container"
        style={size > 0 ? { width: size, height: size } : {}}
      >
        {size > 0 && (
          <div className="progress-chart__text">
            {title && <div className="progress-chart__title">{title}</div>}

            <div
              className="progress-chart__percentage"
              style={{ fontSize: width > 350 ? 90 : width > 275 ? 75 : 60 }}
            >
              {!R.isNil(collected) ? collected : formatPercentageNice(percentage)}
            </div>

            <div className="progress-chart__label">{children}</div>
          </div>
        )}
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          <path
            d={backgroundCircle}
            fill="#e9e9e9"
            transform={`translate(${size / 2} ${size / 2})`}
          />
          <path
            d={percentageArc}
            fill="#69a2a6"
            transform={`translate(${size / 2} ${size / 2})`}
          />
        </svg>
      </div>
    </div>
  );
};

ProgressChart.propTypes = propTypes;
export default ProgressChart;
