import MapPage from "../components/MapPage/MapPage.jsx";
import { ContentfulDepartmentsProvider } from "../common/contentfulDepartments.jsx";
import { MapScrollProvider } from "../components/MapScroll/mapScrollContext.jsx";
import { GoogleMapProvider } from "../components/GoogleMap/googleMapContext.jsx";

const GatsbyMapPage = (props) => (
  <ContentfulDepartmentsProvider>
    <MapScrollProvider>
      <GoogleMapProvider>
        <MapPage />
      </GoogleMapProvider>
    </MapScrollProvider>
  </ContentfulDepartmentsProvider>
);
export default GatsbyMapPage;
