import React, { useCallback, useContext, useMemo, useState } from "react";
import * as R from "ramda";

import { getFeatureBoundingBox } from "../../common/geojson.js";

import statesTopoJson from "./states-10m.json";
import { feature } from "topojson-client";

export const statesGeoJson = feature(
  statesTopoJson,
  statesTopoJson.objects.states,
);

const googleMapContext = React.createContext();

// we need the `map` in context so we can call `panToState` from different places
// in the app.  eg. GoogleMap.jx, DeptInfo.jsx, StateInfo.jsx
export const GoogleMapProvider = (props) => {
  const [map, setMap] = useState();

  const panToState = useCallback(
    (stateName) => {
      const stateBounds = R.compose(
        R.unless(R.isNil, R.zipObj(["west", "south", "east", "north"])),
        getFeatureBoundingBox,
        R.find(R.pathEq(["properties", "name"], stateName)),
      )(statesGeoJson.features);

      if (map && stateBounds) {
        map.fitBounds(stateBounds);
      }
    },
    [map],
  );

  const context = useMemo(() => {
    return { map, setMap, panToState };
  }, [map, panToState]);

  return <googleMapContext.Provider value={context} {...props} />;
};

export function useGoogleMapContext() {
  return useContext(googleMapContext);
}
