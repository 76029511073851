import * as R from "ramda";

/**
 * getFeatureBoundingBox
 * @param  {Object} feature geojson feature object  https://tools.ietf.org/html/rfc7946#section-3.2
 *   {
 *     type: "Feature",
 *     geometry: {
 *       type: // Polygon or MultiPolygon
 *       coordinates: // Polygon or MultiPolygon coordinates
 *     },
 *     properties: { ... }
 *   }
 *
 *   Polygon coordinates (linear ring): [ [longitude, latitude], ...]
 *     https://tools.ietf.org/html/rfc7946#section-3.1.6
 *
 *   MultiPolygon coordinates: [ Polygon coordinates, ... ]
 *
 * @return {Array} a bbox value, array of [west, south, east, north] https://tools.ietf.org/html/rfc7946#section-5.1
 *  where west/east are longitude values, and north/south are latitude values
 *
 * another implementation: https://gist.github.com/JamesChevalier/b03b7423bf330f959076
 */
export function getFeatureBoundingBox(feature = {}) {
  const { geometry = {} } = feature;

  const bounds = R.cond([
    [
      R.propEq("type", "Polygon"),
      R.compose(getCoordinatesBoundingBox, R.path(["coordinates", 0])),
    ],
    [
      R.propEq("type", "MultiPolygon"),
      R.compose(
        getCoordinatesBoundingBox,
        R.unnest,
        R.unnest,
        R.prop("coordinates"),
      ),
    ],
  ])(geometry);

  return bounds;
}

/**
 * getCoordinatesBoundingBox
 * @param  {Array} coordinates
 *   Polygon coordinates (linear ring): [ [longitude, latitude], ...]
 *     https://tools.ietf.org/html/rfc7946#section-3.1.6
 *  @return {Array} a bbox value, array of [west, south, east, north] https://tools.ietf.org/html/rfc7946#section-5.1
 *   where west/east are longitude values, and north/south are latitude values
 */
function getCoordinatesBoundingBox(coordinates) {
  return R.compose(
    R.values,
    R.reduce(
      (bbox, coordinate) => {
        const [longitude, latitude] = coordinate;

        return R.evolve(
          {
            west: R.min(longitude),
            south: R.max(latitude),
            east: R.max(longitude),
            north: R.min(latitude),
          },
          bbox,
        );
      },
      { west: Infinity, south: -Infinity, east: -Infinity, north: Infinity },
    ),
  )(coordinates);
}
