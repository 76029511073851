export const createInputId = (label) => {
  return label
    ? label.replace(/[.,\/#!$%\^&\*;:{}=\-_`~() ]/g, "").toLowerCase()
    : `id${Math.round(Math.random() * 10)}`;
};

export const createErrorId = (label) => {
  const inputId = createInputId(label);
  return `${inputId}Error`;
};

export const createDescriptionId = (label) => {
  const inputId = createInputId(label);
  return `${inputId}Description`;
};
