import { useCallback } from "react";
import cx from "classnames";

import { number, object } from "prop-types";
import {
  deptShape,
  getDeptStatus,
  getDataIconImg,
} from "../../common/dept.jsx";

import { OverlayView } from "@react-google-maps/api";
import { Link } from "@reach/router";

import { getMapEntityUrl } from "../../common/mapUrl.js";

const propTypes = {
  map: object,
  selectedDeptData: deptShape.isRequired,
  zoom: number.isRequired,
};

const HIDDEN_ZOOM = 8;

const SelectedDeptOverlay = (props) => {
  const { map, selectedDeptData, zoom, ...otherProps } = props;

  const containerRef = useCallback(
    (element) => {
      if (!element) {
        return;
      }

      // always do a zoom check
      if (map.getZoom() <= 8) {
        map.setZoom(12);
      }

      const isInBounds = map.getBounds().contains({
        lat: selectedDeptData.latitude,
        lng: selectedDeptData.longitude,
      });

      // nudge it so we can see the label
      if (isInBounds) {
        const { width } = element.getBoundingClientRect();

        // just a single point
        const bounds = {
          east: selectedDeptData.longitude,
          north: selectedDeptData.latitude,
          south: selectedDeptData.latitude,
          west: selectedDeptData.longitude,
        };

        // don't need right padding because the div has a negative x transform
        const padding = {
          top: 48,
          right: width,
          bottom: 48,
          left: 48,
        };

        // https://developers.google.com/maps/documentation/javascript/reference/map#Map.panToBounds
        map.panToBounds(bounds, padding);
      }
      // center it if it's out of the viewport
      else {
        map.panTo({
          lat: selectedDeptData.latitude,
          lng: selectedDeptData.longitude,
        });
      }
    },
    [map, selectedDeptData.latitude, selectedDeptData.longitude],
  );

  // we can't show the overlay if there is no lat/lon
  if (!selectedDeptData.latitude || !selectedDeptData.longitude) {
    return null;
  }

  return (
    <OverlayView
      // use key to reload a new instance every time so we can measure via containerRef callback
      key={selectedDeptData.ori}
      position={{
        lat: selectedDeptData.latitude,
        lng: selectedDeptData.longitude,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        className={cx("map__selected-dept", {
          "map__selected-dept--hidden": zoom < HIDDEN_ZOOM,
        })}
        ref={containerRef}
        {...otherProps}
      >
        <div className="map__selected-dept-icon">
          {getDataIconImg(getDeptStatus(selectedDeptData))}
        </div>
        <div className="map__selected-dept-name">{selectedDeptData.name}</div>

        <Link
          to={getMapEntityUrl(selectedDeptData.stateAbbreviation)}
          className="map__selected-dept-x"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </Link>
      </div>
    </OverlayView>
  );
};

SelectedDeptOverlay.propTypes = propTypes;
export default SelectedDeptOverlay;
