import {} from "prop-types";

const propTypes = {};

const Spinner = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // style="margin: auto; background: none; display: block; shape-rendering: auto;"
      width="48"
      height="48"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <g transform="rotate(0 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.9s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(36 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.8s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(72 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.7s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(108 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.6s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(144 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.5s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.4s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(216 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.3s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(252 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.2s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(288 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.1s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      <g transform="rotate(324 50 50)">
        <rect
          x="46"
          y="10"
          rx="4"
          ry="5.6000000000000005"
          width="8"
          height="20"
          fill="#0c4456"
        >
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          ></animate>
        </rect>
      </g>
      {/* <!-- [ldio] generated by https://loading.io/ --> */}
    </svg>
  );
};

Spinner.propTypes = propTypes;
export default Spinner;
