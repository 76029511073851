import * as R from "ramda";
import { arrayOf, node, number, shape, string } from "prop-types";

const infoTooltipShape = shape({
  label: string,
  tooltipContent: node,
});

const dataFileShape = shape({
  ori: string.isRequired,
  file: string.isRequired, // url
  name: string,
  description: node,
  uploading: string, // ISO date string
  infoTooltip: infoTooltipShape,
});

export const deptShape = shape({
  dataFiles: arrayOf(dataFileShape).isRequired, // can be empty, defaults to [] in contentfulDepartments
  foiaRejections: arrayOf(dataFileShape).isRequired, // can be empty, defaults to [] in contentfulDepartments
  latitude: number, // lat/lon not required
  longitude: number,
  name: string.isRequired,
  ori: string.isRequired,
  stateAbbreviation: string.isRequired,
  stateName: string.isRequired,
});

// department status
export const HAS_DATA = "has-data";
export const HAS_FOIA_REJECTION = "foia-rejection";
export const NO_DATA = "no-data";

import hasDataIconPlainSrc from "../img/has-data-icon-plain.png";
import noDataIconPlainSrc from "../img/no-data-icon-plain.png";
import hasDataIconBorderSrc from "../img/has-data-icon-border.png";
import noDataIconBorderSrc from "../img/no-data-icon-border.png";

// the plain image is for the zoomed out map when the icons are small.  The fancy
// border at this icon size was showing aliasing artifacts.
const icons = {
  hasData: {
    plain: hasDataIconPlainSrc,
    border: hasDataIconBorderSrc,
  },
  noData: {
    plain: noDataIconPlainSrc,
    border: noDataIconBorderSrc,
  },
};

// used by getDataIconImg below, and in createDotsOverlay to draw on the canvas
// default to large zoom, which is the more fancy image (eg. for static images
// in legend, etc)
export const getDataIconSrc = (status, zoom = 13) => {
  const iconKey = status === HAS_DATA ? "hasData" : "noData";
  const iconFancyFactor = zoom <= 10 ? "plain" : "border";
  return icons[iconKey][iconFancyFactor];
};

export const getDataIconImg = (status) => {
  return <img src={getDataIconSrc(status)} alt="" />;
};

export const getDeptStatus = (dept) => {
  const dataFiles = R.propOr([], "dataFiles", dept);

  // if we have at least 1 public data file (even if there is a FOIA rejection)
  if (dataFiles.length > 0) {
    return HAS_DATA;
  }
  // this dept has a foia rejection file
  else if (!R.isNil(dept.foiaRejection)) {
    return HAS_FOIA_REJECTION;
  }
  // otherwise
  else {
    return NO_DATA;
  }
};

export const getDeptColor = (status) => {
  switch (status) {
    case HAS_DATA:
      return "#7ca554"; // green
    case HAS_FOIA_REJECTION:
    case NO_DATA:
      return "#e5ba24"; // yellow
    default:
      return "grey";
  }
};
