import { useMemo } from "react";
import * as R from "ramda";
import {} from "prop-types";

import "./info-panel.scss";

import { getDeptStatus, HAS_DATA } from "../../common/dept.jsx";
import { formatNumber, formatPercentage } from "../../common/format.js";

// import ProgressChart from "../ProgressChart/ProgressChart.jsx";
import { useContentfulDepartments } from "../../common/contentfulDepartments.jsx";
import useContentfulLocationInfo from "../../common/useContentfulLocationInfo.js";

const propTypes = {};

const USInfo = (props) => {
  const allDepartments = useContentfulDepartments();
  const { content } = useContentfulLocationInfo("United States");

  const { collected, total } = useMemo(() => {
    return R.applySpec({
      collected: R.compose(
        R.length,
        R.filter((dept) => getDeptStatus(dept) === HAS_DATA),
      ),
      total: R.length,
    })(allDepartments);
  }, [allDepartments]);

  const percentage = collected / total;

  return (
    <div className="info-panel">
      <h3 className="info-panel__title">United States</h3>

      {/* <div className="info-panel__progress-chart">
        <ProgressChart percentage={percentage}>
          of National Police Department Data has been collected
        </ProgressChart>
      </div> */}

      <div className="info-panel__content">
        <h5>See Our Progress</h5>
        <p>
          Accountable Now has collected data for{" "}
          <strong className="info-panel__out-of">
            {formatNumber(collected)} out of {formatNumber(total)}
          </strong>{" "}
          law enforcement agencies in the United States and its territories.
          This accounts for <strong>{formatPercentage(percentage)}</strong> of
          the national data across states, counties, and city police
          departments.
        </p>
      </div>

      {content && <div className="info-panel__content fancy-a">{content}</div>}
    </div>
  );
};

USInfo.propTypes = propTypes;
export default USInfo;
