import React, { useCallback, useEffect, useRef, useState } from "react";
import * as R from "ramda";
import cx from "classnames";
import { bool, object, string, func } from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import { extractRichText } from "../../common/contentful-extract.jsx";

import Modal from "../Modal/Modal.jsx";
import Spinner from "./Spinner.jsx";
import UploadIcon from "./UploadIcon.jsx";

const propTypes = {
  deptName: string.isRequired,
  ori: string.isRequired,
  uploadState: string,
  setUploadState: func,
  dragState: string,
  handleFileChosen: func,
  hasError: bool,
  selectedFile: object,
  setSelectedFile: func,
};

import {
  UPLOAD_RECAPTCHA_FAILED,
  UPLOAD_FILE_DENIED,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
} from "../../../azure-functions/contentful-upload-dept-file/uploadStatus.js";

// in addition to the backend file upload status from uploadStatus.js, there are a few frontend
// specific states
import {
  DRAG_IDLE,
  UPLOAD_INPROGRESS,
  DRAG_HOVERED,
  UPLOAD_IDLE,
  UPLOAD_UPLOADING,
  UPLOAD_BAD_EXTENSION,
  validExtensions,
} from "./upload-helpers.js";

// used for <input> accept attribute and error message
// ".xlsx, .xls, .csv, .pdf"
const validExtensionsString = R.compose(
  R.join(", "),
  R.map((extension) => `.${extension}`),
)(validExtensions);

const FileUpload = React.forwardRef((props, ref) => {
  const {
    deptName,
    uploadState,
    setUploadState,
    dragState,
    handleFileChosen,
    hasError,
    selectedFile,
    setSelectedFile,
  } = props;

  const containerRef = useRef();
  const fileInputRef = useRef();

  const contentfulData = useStaticQuery(query);
  const { uploadSuccess, uploadFailure } = R.compose(
    R.evolve({
      uploadSuccess: extractRichText,
      uploadFailure: extractRichText,
    }),
    R.defaultTo({}),
    R.prop("contentfulMapPage"),
  )(contentfulData);

  const handleChooseFileClick = useCallback((e) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    // this shouldn't happen, right??
    else {
      console.error("can't find file input!");
    }
  }, []);

  return (
    <div className="file-upload" ref={containerRef}>
      <div className="form__item">
        {" "}
        {uploadState !== UPLOAD_FILE_SUCCESS && (
          <label
            htmlFor="fileUpload"
            aria-required="true"
            className="form__label"
          >
            File*
          </label>
        )}
        {uploadState === UPLOAD_IDLE || uploadState === UPLOAD_BAD_EXTENSION ? (
          <>
            <div className="file-upload__dotted-area">
              <input
                type="file"
                className=""
                style={{ display: "none" }}
                ref={fileInputRef}
                aria-hidden="true"
                onInput={handleFileChosen}
                accept={validExtensionsString}
                id="fileUpload"
                required="required"
              />{" "}
              <UploadIcon className="file-upload__upload-icon" />
              <p>
                Drag a file here
                <br />({validExtensionsString})
              </p>
              <button
                type="button"
                className="btn btn--dark"
                onClick={handleChooseFileClick}
                aria-hidden="true"
                aria-describedby="fileError"
              >
                or select a file from your device
              </button>
            </div>
          </>
        ) : uploadState === UPLOAD_INPROGRESS ? (
          <div className="file-upload__dotted-area file-upload__dotted-area--solid">
            <p className="file-upload__uploading" role="alert">
              {/* TODO: get updated copy */}
              Your file {selectedFile && selectedFile.name} is ready! To submit,
              please click the Upload Data below.
            </p>
            <button
              type="button"
              className="btn btn--text btn--small"
              onClick={() => {
                setUploadState(UPLOAD_IDLE);
                setSelectedFile(null);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
                aria-hidden="true"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
              clear
            </button>
          </div>
        ) : uploadState === UPLOAD_UPLOADING ||
          uploadState === UPLOAD_RECAPTCHA_FAILED ? (
          <div className="file-upload__dotted-area">
            <p className="file-upload__uploading" role="alert">
              <Spinner className="file-upload__spinner" />
              Uploading...
            </p>
          </div>
        ) : uploadState === UPLOAD_FILE_SUCCESS ? (
          <div className="info-panel__data-uploaded">{uploadSuccess}</div>
        ) : uploadState === UPLOAD_FILE_DENIED ? (
          <div className="info-panel__data-uploaded">
            <h5>Oh no!</h5>
            <p role="alert">ReCAPTCHA thinks you're a robot! </p>
            <button
              type="button"
              className="btn"
              onClick={() => setUploadState(UPLOAD_IDLE)}
            >
              Try again
            </button>
          </div>
        ) : uploadState === UPLOAD_FILE_ERROR ? (
          <div className="info-panel__data-uploaded">
            <div>{uploadFailure}</div>
            <button
              type="button"
              className="btn"
              onClick={() => setUploadState(UPLOAD_IDLE)}
            >
              Try again
            </button>
          </div>
        ) : null}
        <div className="form__error" role="alert" id="fileError">
          {hasError &&
            " Please include a file. Only files of type .csv, .xls, .xlsx, .pdf, .doc are accepted."}
        </div>
        <div
          className={cx("file-upload__full-screen-drop-area", {
            "file-upload__full-screen-drop-area--is-visible":
              dragState === DRAG_HOVERED,
          })}
          ref={ref}
        >
          <div>
            <UploadIcon />
            Drop file to upload data for {deptName}
          </div>
        </div>
        <Modal
          isOpen={uploadState === UPLOAD_BAD_EXTENSION}
          onClose={() => setUploadState(UPLOAD_IDLE)}
        >
          <div className="file-upload__bad-extension-message">
            <h5>Oh no!</h5>
            <p>
              Please upload one of these file formats:
              <br />
              <strong>{validExtensionsString}</strong>
            </p>
            <button
              type="button"
              className="btn"
              onClick={() => setUploadState(UPLOAD_IDLE)}
            >
              Try again
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
});

FileUpload.propTypes = propTypes;
export default FileUpload;

const query = graphql`
  {
    contentfulMapPage {
      uploadSuccess {
        raw
      }
      uploadFailure {
        raw
      }
    }
  }
`;
