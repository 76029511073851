import React from "react";
import { string, bool, func } from "prop-types";
import { createInputId, createErrorId } from "../../common/id-helpers.js";

const propTypes = {
  hasError: bool,
  required: bool,
  label: string,
  placeholder: string,
  errorMsg: string,
  type: string,
  handleBlur: func,
};

const TextInput = React.forwardRef((props, ref) => {
  const { handleBlur, hasError, required, label, placeholder, errorMsg, type } =
    props;

  return (
    <div className="form__item">
      <label
        htmlFor={createInputId(label)}
        aria-required="true"
        className="form__label"
      >
        {label}
      </label>
      <input
        className="form__input"
        aria-required={required}
        type={type || "text"}
        id={createInputId(label)}
        ref={ref}
        aria-describedby={createErrorId(label)}
        required={required}
        placeholder={placeholder || ""}
        onBlur={handleBlur}
      />
      <div role="alert" id={createErrorId(label)} className="form__error">
        {hasError && errorMsg}
      </div>
    </div>
  );
});

TextInput.propTypes = propTypes;
export default TextInput;
