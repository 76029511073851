import { useMemo } from "react";
import cx from "classnames";
import { node } from "prop-types";
import "./map-panel.scss";

import useBoundingClientRect from "../../common/useBoundingClientRect.js";
import { useMapScroll } from "../MapScroll/mapScrollContext.jsx";
import useWindowSize from "../../common/useWindowSize.js";

import GoogleMap from "../GoogleMap/GoogleMap.jsx";
import Search from "../Search/Search.jsx";

const propTypes = {
  children: node,
};

const MapPanel = (props) => {
  const { children } = props;

  const { mapHolderRef, mapPanelRef } = useMapScroll();

  const [{ width: mapHolderWidth }] = useBoundingClientRect(mapHolderRef);

  const { mapHeight, sideBySide } = useResponsiveMap();

  return (
    <div
      className={cx("container container--bleed map-panel", {
        "map-panel--side-by-side": sideBySide,
      })}
      ref={mapPanelRef}
    >
      <div className="map-panel__left">
        <div className="map-panel__search">
          <Search placeholder="Search by location or department" />
        </div>
        {children}
      </div>
      <div className="map-panel__right">
        <div
          className="map-panel__map"
          style={{ height: mapHeight }}
          ref={mapHolderRef}
        >
          {mapHolderWidth && mapHeight && (
            <GoogleMap
              width={mapHolderWidth}
              height={mapHeight}
              containerRef={mapHolderRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

MapPanel.propTypes = propTypes;
export default MapPanel;

function useResponsiveMap() {
  const [windowWidth, windowHeight] = useWindowSize();

  // 910 matches $bp-large-min
  const isLargeScreen = useMemo(() => windowWidth >= 910, [windowWidth]);

  const mapHeight = useMemo(() => {
    return isLargeScreen ? windowHeight : 500;
  }, [isLargeScreen, windowHeight]);

  return { mapHeight, sideBySide: isLargeScreen };
}
